import React from "react";
import { Link } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import Layout from "../components/layout";
import HeaderLight from "./../components/headerLight";
import Footer from "./../components/footer";

require("../css/staticPages.css");

class ShippingPolicy extends React.Component {
    render() {
        return (
            <div>
                <HeaderLight pathname="/shipping-policy" />
                <div className="container NC-vision1 ">
                    <div className="row mar-top-btm">
                        <div className="col-sm-12 pad0 ">
                            <div> <h3 className="bannerHeading">Shipping Policy</h3> </div>

                            <div className="row eachRow">
                                <h5 className="subheading">Cancellation by Site Customer{" "}</h5>
                                <p>You as a customer can cancel your order anytime up to the cut-off time of the slot for which you have placed an order by calling our customer service. In such a case we will refund any payments already made by you for the order as a Store Credit, which can be used on future purchases. If we suspect any fraudulent transaction by any customer or any transaction which defies the terms & conditions of using the website, we at our sole discretion could cancel such orders. We will maintain a negative list of all fraudulent transactions and customers and would deny access to them or cancel any orders placed by them.</p>
                            </div>

                            <div className="row eachRow">
                                <h5 className="subheading">SHIPPING AND DELIVERY{" "}</h5>
                                <ol>
                                    <li><p>Packages are generally dispatched within 2 days after receipt of payment and are shipped via reputed couriers with tracking. Whichever shipment choice you make, we will provide you with a link to track your package online.</p></li>
                                    <li><p> Boxes are amply sized and your items are well-protected.</p></li>
                                    <li><p> Our courier partners will be able to deliver the shipment to you during working days between Monday through Saturday: 9am to 7pm. Working days exclude public holidays and Sundays. Delivery time is subject to factors beyond our control including unexpected travel delays from our courier partners and transporters due to weather conditions and strikes.</p></li>
                                    <li><p>As soon as your package ships, we will email you your package tracking information.</p></li>
                                </ol>
                            </div>

                            <div className="row eachRow">
                                <h5 className="subheading">RETURN POLICY{" "}</h5>
                                <p>We at Native Circle works very hard to deliver the best products and shopping experience to you. However, sometimes because of the scale of operation a few exceptional cases do happen. If for any reason you do not feel satisfied with the product delivered to you then please raise a query from Contact Window.</p>

                                <p>We will try our best to replace the product to your satisfaction and if that is not possible because of some operational constraint then we will provide full refund as Store Credit.  In all such circumstances, before using the product and discarding packaging, please get in touch with our Customer Service Team who will guide you on the process for the same.</p>

                                <p>To make things fair for all of us and provide you with the benefits of the Return Policy, we adhere to below mention points.<br></br>
                                    All items to be returned must be unused and in their original condition. The original tags, packaging should be intact.</p>

                                <p>In case we are unable to arrange a reverse pickup for you, we’ll inform you and request you to return the item via any other courier. We will refund the full courier amount.</p>

                                <p>We would not be able to accept a product if it shows sign of being broken or tampered by you. We expect that while returning the product you will pack the product very safely and securely. We won't be able to entertain any damage/lost in Transit (For example:- If a complaint was raised for Wrong Product Delivery and the sent item got damaged in the transit then we will not be responsible for the damage and eventually may not be in a position of replacement for the original one as well)</p>

                                <p><span className="pleaseNote">Please Note:</span> We may not be able to exchange products bought during sale or discount. Replacement/exchange is subject to product/ stock availability.</p>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default ShippingPolicy;
